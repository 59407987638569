const info = {
    state: {
        menu: 'index'
    },
    mutations: {
        SET_MENU: (state, menu) => {
            state.menu = menu
        }
    }
}
export default info