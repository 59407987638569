import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const constantRoutes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: (resolve) => require(['@/views/index'], resolve),
  },
  {
    path: '/product',
    name: 'product',
    component: (resolve) => require(['@/views/product'], resolve),
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: (resolve) => require(['@/views/aboutUs'], resolve),
  },
]

export default new Router({
  // mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
