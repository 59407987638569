<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  overflow: auto;
  background: #fff;
  color: #333;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.homeWrap {
    padding-top: 50px;
    min-width: 1000px;
}
.headerWrap {
    height: 60px;
    background: #A30014;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}
.headerWrap .con {
    /* width: 1000px; */
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-left: 300px;
    
}
.headerWrap .logo {
    position: absolute;
    left: 0;
    top: 0;
    height: 60px;
}
.headerWrap .menuList {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #000;
    line-height: 60px;
}
.headerWrap .menuList .item {
    font-weight: bold;
    margin-left: 70px;
    cursor: pointer;
}
.headerWrap .menuList .item:hover {
    color: #fff
}
.headerWrap .menuList .item.active {
    color: #fff;
}

.footerWrap {
  line-height: 70px;
  color: #fff;
  font-size: 14px;
  background: #333;
  text-align: center;
}
.footerWrap .el-link {
  color: #fff !important;
}
.footerWrap .el-link:hover {
  color: #409eff !important;
}
.flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
